<template>
	<div class="mhome">
		<div class="mhome-wrap mlive-list" @scroll="handleScroll" v-infinite-scroll="handleLiveScroll">
			<router-link class="item" v-for="(item,index) in liveList" :key="index"
				:to="{name:$route.name=='mLiveListPlayback'?'mLivePlayback':$route.name=='mLiveListNotice'?'mLiveEdit':'mLiveInfo',params:{id:item.live_id}}">
				<img :src="item.cover0" @error="imageLoadErrorLive" alt="" class="pic">
				<div class="item-main" v-if="$route.name=='mLiveListNotice'">
					<div class="name">
						<el-tag effect="dark">{{$t('mobile.live.tag1')}}</el-tag>
						<em>{{item.title}}</em>
					</div>
				</div>
				<div class="item-main" v-else>
					<div class="name"><em>{{item.title}}</em></div>
					<div class="text">
						<el-tag :type="$route.name=='mLiveListPlayback'?'info':'danger'" effect="dark">
							{{$route.name=='mLiveListPlayback'?$t('mobile.live.tag3'):$t('mobile.live.tag2')}}
						</el-tag>
						<span>{{item.views}} {{$t('mobile.tags.views')}}</span>
					</div>
				</div>
			</router-link>
		</div>
	</div>
</template>

<script>
	import { getLiveList } from '@/api/mobile/live';
	export default {
		data() {
			return {
				liveList: [],
				pageSize: 10,
				pageCurr: 1,
				pageTotal: 0,
				pageflag: true,
				isScroll: false
			}
		},
		mounted() {
			this.getLivelist();
		},
		methods: {
			imageLoadErrorLive(e) {
				e.target.src = this.$imageErrorLive;
			},
			getLivelist() {
				let loading = this.$loading();
				let param = {
					isall: 1,
					issubscribe: 1,
					page_index: (this.pageCurr - 1) * this.pageSize,
					page_size: this.pageSize,
					uuid: this.$store.getters.uuid,
					app_key: this.$store.getters.appKey
				}
				if (this.$route.name=='mLiveListPlayback') {
					param.status = 2;
				} else if (this.$route.name=='mLiveListNotice') {
					param.status = 0;
					param.starttime = 1;
				}
				getLiveList(param).then(response => {
					if (response.data.list.length > 0) {
						this.liveList = this.liveList.concat(response.data.list);
					} else {
						this.pageflag = false;
					}
					this.pageTotal = parseInt(response.data.total);
					loading.close();
				}).catch(() => {
					loading.close();
				})
			},
			handleScroll($event) {
				// var scrollTop = $event.target.scrollTop;
			},
			handleLiveScroll() {
				if(this.isScroll) {
					this.pageCurr++;
					if (this.pageflag) {
						this.getLivelist();
					}
				} else {
					this.isScroll = true
				}
			},
		}
	}
</script>

<style lang="scss" scoped>
	.mlive-list {
		background-color: #FFFFFF;

		.item {
			width: 50%;
			float: left;
			position: relative;

			.pic {
				width: 100%;
				height: 6rem;
				object-fit: cover;
			}

			.item-main {
				left: 0;
				bottom: 0;
				width: 100%;
				padding: 0.32rem;
				box-sizing: border-box;
				position: absolute;

				.name {
					color: #FFFFFF;
					font-size: 0.416rem;
					height: 0.544rem;
					line-height: 0.544rem;
					align-items: center;
					display: flex;

					em {
						flex: 1;
						width: 0;
						overflow: hidden;
						white-space: nowrap;
						text-overflow: ellipsis;
					}
				}

				.text {
					color: #FFFFFF;
					font-size: 0.384rem;
					line-height: 0.544rem;
					margin-top: 0.128rem;
				}

				.el-tag {
					border: none;
					height: 0.512rem;
					line-height: 0.512rem;
					font-size: 0.32rem;
					padding: 0 0.192rem;
					border-radius: 0.256rem;
					margin-right: 0.192rem;
				}
			}
		}
	}
</style>
